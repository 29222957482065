import isChoiceModule from './isChoiceModule'
import isDatasetModule from './isDatasetModule'
import isOpenAnswerModule from './isOpenAnswerModule'

const SUPPORTED_MODULE_FNS = [isOpenAnswerModule, isChoiceModule]

export const isModuleSyntheticCompatible = module => {
	if (isDatasetModule(module) === false) {
		return true
	}

	return SUPPORTED_MODULE_FNS.some(fn => fn(module))
}
